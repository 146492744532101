<template>
     <!-- <div v-if="isLoading">
        <div class="text-center text-danger screen-center">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
    </div> -->
    <div>
        <div class="breadcrumb">
            <h1>Modificar cliente</h1>
            <div class="ml-3 pt-2">
                <span class="font-weight-bold">
                    N°: {{ numeroRegistro }}
                </span>
            </div>
        </div>
        <div class="separator-breadcrumb border-top"></div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-sm-12">
                                    <button class="btn th-custom-color" @click="update">Guardar</button>
                                    <router-link :to="{ name: 'cliente-index' }">
                                <button
                                    style="margin-left: 10px;"
                                    class="btn btn-white border border-light"
                                >
                                    Regresar
                                </button>
                            </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <label for="nombre_comercial" class="col-sm-4 col-form-label text-right">Nombre comercial: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" placeholder="Ingrese el Nombre Comercial" id="nombre_comercial"
                                                v-model="nombreComercial"
                                                required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="razon_social" class="col-sm-4 col-form-label text-right">{{ empresa.texto_razon_social }}
                                            :</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" :placeholder="'Ingrese ' + empresa.texto_razon_social" id="razon_social"
                                                v-model="razonSocial">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="ci_nit"
                                            class="col-sm-4 col-form-label text-right">{{ empresa.texto_numero_contribuyente }}: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" :placeholder="'Ingrese ' + empresa.texto_numero_contribuyente" id="ci_nit"
                                                v-model="ciNit"
                                                required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="codigo_cliente" class="col-sm-4 col-form-label text-right">Código de cliente: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-3 mb-4 was-validated">
                                            <input type="text" class="form-control" placeholder="Ingrese el Codigo de Cliente" id="codigo_cliente"
                                                v-model="codigoCliente"
                                                required>
                                            <div class="valid-tooltip codigo-sugerido">Código sugerido.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="fecha_trabajo" class="col-sm-4 col-form-label text-right">Fecha de trabajo: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-3">
                                            <date-picker
                                                id="fecha_trabajo"
                                                v-model="fechaTrabajo"
                                                lang="es"
                                                format="YYYY-MM-DD"
                                                type="date"
                                                value-type="date"
                                                width="100%">
                                            </date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <label for="celular_telefono" class="col-sm-4 col-form-label text-right">Teléfono: </label>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" placeholder="Ingrese el Telefono" id="celular_telefono"
                                            v-model="celularTelefono">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="email" class="col-sm-4 col-form-label text-right">Emails: </label>
                                        <div class="col-sm-6">
                                           <vue-tags-input v-model="tag" :tags="tags" class="tag-custom text-15" @tags-changed="newTags => tags = newTags" placeholder="correo@gmail.com"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="direccion" class="col-sm-4 col-form-label text-right">Dirección: </label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" placeholder="Ingrese la Direccion" id="direccion"
                                                v-model="direccion">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="pais" class="col-sm-4 col-form-label text-right">País: </label>
                                        <div class="col-sm-4">
                                            <multiselect
                                                id="pais"
                                                v-model="paisSelected"
                                                track-by="id"
                                                label="descripcion"
                                                placeholder="Pais"
                                                :options="paises"
                                                @select="getCiudad"
                                                :searchable="true">
                                            </multiselect>
                                        </div>
                                        <div class="col-sm-4">
                                            <!-- <input type="text" class="form-control" placeholder="Ingrese la ciudad"
                                                v-model="ciudad"> -->
                                            <multiselect
                                            id= "ciudad"
                                            v-model="ciudadSelected"
                                            track-by="id"
                                            label="nombre"
                                            placelholder="Ciudad"
                                            :options="ciudades"
                                            :loading="loadingCiudad"
                                            :searchable="true"
                                            >

                                            </multiselect>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="email" class="col-sm-4 col-form-label text-right">Límite de crédito (Bs):</label>
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="0.00"
                                                v-model="limiteCredito">
                                        </div>
                                        <!-- p´ruebas de de test -->
                                        <label for="origen" class="col-auto col-form-label text-right">Origen:</label>
                                        <div class="col-sm-4">
                                            <multiselect
                                                id="origen"
                                                v-model="origenCliente"
                                                track-by="id"
                                                label="descripcion"
                                                placeholder="Origen"
                                                :options="origenesClientes"
                                                @select="getCliente"
                                                :searchable="true">
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group row">
                                        <label for="descripcion" class="col-sm-2 col-form-label text-right">Descripción:</label>
                                        <div class="col-sm-10">
                                            <textarea id="descripcion" cols="1" rows="4" v-model="descripcion"
                                                placeholder="Ingrese una Descripcion"
                                                class="form-control no-resize"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'

import VueTagsInput from '@johmun/vue-tags-input';

export default {
    name: 'edit',
    components: {
        DatePicker,
        Multiselect,
        VueTagsInput,
    },
    created() {
        this.getDataEdit();
    },
    data() {
        return {};
    },
    methods: {
        ...call("cliente/clienteUpdate", [
            "getDatoInicial",
            "getCiudad",
            "getCliente",
            "updateUser",
        ]),
        async getDataEdit() {
            const EDIT_OK = await this.getDatoInicial(atob(this.$route.params.id));
            if (EDIT_OK) {
                this.isLoading = false;
            }
        },
        async update(){
            this.isLoading = true;
            const UPDATE_OK = await this.updateUser(atob(this.$route.params.id));
            if (UPDATE_OK) {
                this.$router.push({ name: 'cliente-index' });
            }
            this.isLoading = false;
        }
    },
    computed: {
        ...get("cliente/clienteUpdate", [
            "paises",
            "ciudades",
            "origenesClientes",
            "numeroRegistro",
            "loadingCiudad"
        ]),
        ...sync("cliente/clienteUpdate", [
        "nombreComercial",
        "isLoading",
        "empresa",
        "codigoCliente",
        "fechaTrabajo",
        "celularTelefono",
        "tag",
        "tags",
        "direccion",
        "paisSelected",
        "ciudadSelected",
        "limiteCredito",
        "origenCliente",
        "descripcion",
        "razonSocial",
        "ciNit",
        ]),
    },
    beforeDestroy() {
        this.$store.dispatch('cliente/clienteUpdate/reset');
    }
}
</script>